import React from 'react';
import Helmet from 'react-helmet';
import { findIndex, isMatch, uniqBy } from 'lodash';
import { graphql, Link as GatsbyLink } from 'gatsby';

import Section from '@nib-components/section';
import Heading from '@nib-components/heading';
import Copy from '@nib-components/copy';
import Container from '@nib-components/container';
import Card from '@nib/card';
import Link from '@nib-components/link';
import colors from '@nib-components/colors';
import { Stack, Tiles } from '@nib/layout';

import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Layout from '../components/Layout';
import metrics from '../metrics';
import { ContentfulNewsArticleNode, ContentfulNewsArticleSection } from '../constructs/models';

import { compareDateString, getFormattedDateFromDateString } from '../utils/moment-utils';

const title = 'Latest News And Media';
const metaDescription = 'View all the latest news and media releases from the nib team.';

export const newsAndMediaPageQuery = graphql`
  query NewsAndMediaPageQuery {
    allContentfulNewsArticle {
      edges {
        node {
          title
          urlSlug
          startDate
          heroType
          videoUrl
          keywords
          section {
            title
            id
            order
          }
          showAsHeroArticle
          summary {
            summary
          }
          thumbnail {
            title
            gatsbyImageData(layout: FULL_WIDTH)
            file {
              url
            }
          }
          articleContent {
            childMarkdownRemark {
              html
            }
          }
          heroImage {
            title
            gatsbyImageData(layout: FULL_WIDTH)
            file {
              url
            }
          }
        }
      }
    }
  }
`;

interface GraphQlNewsArticle {
  allContentfulNewsArticle: ContentfulNewsArticle;
}

interface ContentfulNewsArticle {
  edges: ContentfulNewsArticleEdgesShape[];
}

interface ContentfulNewsArticleEdgesShape {
  node: ContentfulNewsArticleNode;
}

interface NewsAndMediaProps {
  data: GraphQlNewsArticle;
}

const NewsAndMedia = (props: NewsAndMediaProps): JSX.Element => {
  const { data } = props;

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    '@id': 'WebPage',
    identifier: 'https://www.nib.co.nz/news-and-media',
    url: 'https://www.nib.co.nz/news-and-media',
    description: 'Great value health insurance',
    name: ['News and Media | nib'],
    isPartOf: 'https://www.nib.co.nz',
  };
  const JSONschema = JSON.stringify(schema);

  return (
    <Layout>
      <Helmet>
        <title>{title} | nib</title>
        <meta name="description" content={metaDescription} />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>
      <Section>
        <Stack space={{ xs: 4, md: 5, lg: 7 }}>
          <HeaderSection />
          <ArticlesSection articles={data.allContentfulNewsArticle} />
        </Stack>
      </Section>
    </Layout>
  );
};

const HeaderSection = (): JSX.Element => {
  return (
    <Container role="section">
      <Stack space={3}>
        <Link id="ga-inlink-static-site-page-9" component={GatsbyLink} to="/about-nib">
          &lt; Back
        </Link>
        <Heading size={{ sm: 2, lg: 1 }} component="h1">
          News and Media
        </Heading>
        <Copy measure={false}>
          For media enquiries please contact us on 0800 44 5573 or email{' '}
          <Link href="mailto:mediaenquiresgroup@nib.com.au">mediaenquiresgroup@nib.com.au</Link>
        </Copy>
      </Stack>
    </Container>
  );
};

interface ArticleSectionProps {
  articles: ContentfulNewsArticle;
}

const ArticlesSection = (props: ArticleSectionProps): JSX.Element => {
  const { articles } = props;

  const sectionInfo = getSectionInfo(articles.edges).sort((a, b) => a.order - b.order);

  return (
    <Container role="section">
      <Stack space={7}>
        {sectionInfo.map((info, index) => (
          <NewsSection articles={articles.edges} sectionInfo={info} key={index} />
        ))}
      </Stack>
    </Container>
  );
};

interface FeatureArticleProps {
  featuredArticle: ContentfulNewsArticleNode;
}

const FeatureArticle = (props: FeatureArticleProps): JSX.Element => {
  const { featuredArticle } = props;

  const { title, thumbnail, summary, startDate, heroImage, urlSlug } = featuredArticle;

  return (
    <Container>
      <Card
        collapseBelow="md"
        horizontalLayout={true}
        image={
          (heroImage && heroImage.gatsbyImageData.images.fallback.src) ||
          thumbnail.gatsbyImageData.images.fallback.src
        }
        imageAlt={(heroImage && heroImage.title) || thumbnail.title}
      >
        <Card.Content title={title} titleSize={2} titleComponent="h2">
          <Stack space={4}>
            <Stack space={1}>
              <Copy small={true} color={colors.grumpy}>
                {getFormattedDateFromDateString(startDate)}
              </Copy>
              <Copy>{summary.summary}</Copy>
            </Stack>
            <Link href={`/news-and-media/news-details/${urlSlug}`}>Read more</Link>
          </Stack>
        </Card.Content>
      </Card>
    </Container>
  );
};

const getSectionInfo = (
  articlesArr: ContentfulNewsArticleEdgesShape[]
): ContentfulNewsArticleSection[] => {
  return uniqBy(articlesArr, (article) => article.node.section.id).map((article) => {
    return article.node.section;
  });
};

const getFeaturedArticle = (
  arr: ContentfulNewsArticleEdgesShape[]
): ContentfulNewsArticleEdgesShape | undefined => {
  const featuredArticles = arr.filter((article) => article.node.showAsHeroArticle);
  const sortedFeaturedArticles = featuredArticles.sort((a, b) =>
    compareDateString(a.node.startDate, b.node.startDate)
  );

  // From Contentful:
  // Note: the most recent article with "Show as Hero Article" turned on will be displayed as the hero
  return sortedFeaturedArticles.length ? sortedFeaturedArticles[0] : undefined;
};

interface NewsSectionProps {
  articles: ContentfulNewsArticleEdgesShape[];
  sectionInfo: ContentfulNewsArticleSection;
}

const NewsSection = (props: NewsSectionProps): JSX.Element => {
  const { articles, sectionInfo } = props;

  const articlesWithinSection = articles.filter(
    (article) => article.node.section.id === sectionInfo.id
  );

  const featuredArticle = getFeaturedArticle(articlesWithinSection);

  if (featuredArticle) {
    const featuredArticleIndex = findIndex(articlesWithinSection, (article) =>
      isMatch(article.node, featuredArticle.node)
    );
    articlesWithinSection.splice(featuredArticleIndex, 1);
  }

  return (
    <Stack space={4}>
      {featuredArticle ? <FeatureArticle featuredArticle={featuredArticle.node} /> : null}
      {/*
      From Contentful:
      Note: the most recent article with "Show as Hero Article" turned on will be displayed as the hero,
      so they will need to display all published artciles within the section
      */}
      <NewsList articles={articlesWithinSection} sectionTitle={sectionInfo.title} />
    </Stack>
  );
};

interface NewsListProps {
  articles: ContentfulNewsArticleEdgesShape[];
  sectionTitle: string;
}

const NewsList = (props: NewsListProps): JSX.Element | null => {
  const { articles, sectionTitle } = props;

  if (!articles.length) {
    return null;
  }

  const sortedArticles = articles.sort((a, b) =>
    compareDateString(a.node.startDate, b.node.startDate)
  );

  return (
    <Stack space={4}>
      <Heading size={2}>{sectionTitle}</Heading>
      {/* TODO: Change the tiles component to support flexible cards */}
      <Tiles space={4} columns={{ sm: 1, md: 2, lg: 3 }}>
        {sortedArticles.map((article, index) => (
          <NewsCard cardDetails={article.node} key={index} />
        ))}
      </Tiles>
    </Stack>
  );
};

const BackgroundImg = styled.img`
  height: 275px;
  width: 100%;
  background-image: ${(props) => `url('${props.backgroundImage}')`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: ${colors.sneezy};

  ${breakpoint('lg')`
    height: 200px;
  `};

  ${breakpoint('xl')`
    height: 250px;
  `};

  ${breakpoint('xxl')`
    height: 300px;
  `};
`;

interface NewsCardProps {
  cardDetails: ContentfulNewsArticleNode;
}

const NewsCard = (props: NewsCardProps): JSX.Element => {
  const { cardDetails } = props;

  const { thumbnail, startDate, summary, title, urlSlug } = cardDetails;

  // TODO: Change the Card component so that the `Read more` links are
  // inline with the adjacent cards
  return (
    <Card
      withBoxShadow={true}
      height="100%"
      image={<BackgroundImg backgroundImage={thumbnail.gatsbyImageData.images.fallback.src} />}
      imageAlt={thumbnail.title}
    >
      <Card.Content title={title}>
        <Stack space={4}>
          <Stack space={1}>
            <Copy small={true} color={colors.grumpy}>
              {getFormattedDateFromDateString(startDate)}
            </Copy>
            <Copy>{summary.summary}</Copy>
          </Stack>
          <Link href={`/news-and-media/news-details/${urlSlug}`}>Read more</Link>
        </Stack>
      </Card.Content>
    </Card>
  );
};

export default metrics({ pageName: 'news-and-media' })(NewsAndMedia);
